<template>
  <v-container>
    <v-calendar
      :start="displayDate"
      ref="calendar"
      color="primary"
      type="day"
      interval-height="90"
      :hide-header="true"
      :events="appointments"
      :event-color="getEventColor"
      @click:event="showEvent"
      style="height: calc(100vh - 160px)"
    >
      <template v-slot:day-body="{ date, week }">
        <div
          class="v-current-time"
          :class="{ first: date === week[0].date }"
          :style="{ top: nowY }"
          v-if="$refs.calendar && date === week[0].date"
        >
          <div>
            {{ $refs.calendar.times.now.time }}
          </div>
        </div>
      </template>

      <template v-slot:day-header="{ present }">
        <template
          v-if="present"
          class="text-center"
        > Today </template>
      </template>

      <template v-slot:event="{ event }">
        <p class="calendar-event-label left-aligned">
          <em>{{ event.start_time }} </em>
          <em>{{ event.customer }}</em>: {{ event.task }}
          <span v-if="event.isTwoCarer && event.secondCarer">(with {{event.secondCarer}})</span>
        </p>
        <p class="calendar-event-label left-aligned">
          {{ event.address.add1 }}, {{ event.address.pcode }}
        </p>
      </template>
    </v-calendar>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "CalendarContainer",
  data: () => ({
    ready: false,
    now: "",
  }),
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
  },
  computed: {
    nowY ()
    {
      return this.ready
        ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + "px"
        : "-15px";
    },

    displayDate ()
    {
      return dayjs(this.date).format("YYYY-MM-DD");
    },
  },

  /**
   * initialize the now-bar and focus setter
   */
  mounted ()
  {
    let interval = setInterval(() =>
    {
      if (this.$refs.calendar)
      {
        this.ready = true;
        this.scrollToTime();
        this.updateTime();
        clearInterval(interval);
      }
    }, 500);
  },

  methods: {
    getCurrentTime ()
    {
      return this.$refs.calendar
        ? this.$refs.calendar.times.now.hour * 60 +
        this.$refs.calendar.times.now.minute
        : 0;
    },
    scrollToTime ()
    {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.$refs.calendar.scrollToTime(first);
    },

    updateTime ()
    {
      let updateInterval = setInterval(() =>
      {
        if (!this.$refs.calendar)
        {
          clearInterval(updateInterval);
          return;
        }
        this.$refs.calendar.updateTimes();
      }, 1000);
    },

    /**
     * Calculate the color of the appointment nodes
     */
    getEventColor (oAppointment)
    {
      var iAppTime = this.getAppRelativeTime(oAppointment);
      switch (iAppTime)
      {
        case -1:
          if (oAppointment.status === "CHECKED_OUT" || oAppointment.status === "FRUSTRATED" || !oAppointment.active)
          {
            return "var(--calendar-event-done)";
          } else
          {
            return "var(--calendar-event-actionrequired)";
          }
          break;
        case 0:
          if (oAppointment.status === "CHECKED_OUT" || oAppointment.status === "FRUSTRATED")
          {
            return "var(--calendar-event-done)";
          } else
          {
            return "var(--calendar-event-actionrequired)";
          }
          break;
        case 1:
          if (oAppointment.status === "CHECKED_OUT" || oAppointment.status === "FRUSTRATED")
          {
            return "var(--calendar-event-done)";
          } else
          {
            return "var(--calendar-event-upcoming)";
          }
          break;
      }
    },

    /**
     * Tell if the appointment is past, present, future
     *
     * -1 if the appointment is in the past
     * 0 if the appointment started but hasn't finished yet
     * 1 if the appointment is in the future
     */
    getAppRelativeTime (oAppointment)
    {
      var oNow = dayjs();
      var oStart = dayjs(oAppointment.start);
      var oEnd = dayjs(oAppointment.end);

      if (oNow.isAfter(oEnd))
      {
        return -1;
      } else if (oNow.isAfter(oStart))
      {
        return 0;
      } else
      {
        return 1;
      }
    },

    /**
     * Notify the parent that an appointment was pressed
     */
    showEvent ({ event })
    {
      this.$emit("showEventDetails", event);
    },
  },
};
</script>

<style lang="scss">
.calendar-container {
  height: 100%;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  > div {
    position: absolute;
    background-color: #ea4335;
    color: white;
    width: auto;
    padding: 0px 5px 0px 7.5px;
    height: 30px;
    line-height: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateY(-50%);
    right: 0;
    text-align: center;
    font-family: monospace;
    font-size: 15px;
  }
}

.theme--light.v-calendar-daily {
  border-bottom: #e0e0e0 1px solid;
}

.theme--light.v-calendar-daily .v-calendar-daily__day {
  border-bottom: none !important;
}
</style>
